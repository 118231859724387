import './form.css'

import { Button, CircularProgress, Flex } from '@stars-ecom/shared-atoms-ui'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import * as Yup from 'yup'

import { regExpPhoneNumber, regExpZipCode, sectionAdr } from '../constants/account'
import { UserContext, WebsiteContext } from '../context'
import { Section } from './fields'

const NewAddress = (props) => {
  const { createCustomerAddress, handleRead, displayLabel = true } = props
  const [loading, setLoading] = useState(false)
  const currentUser = useContext(UserContext)
  const customer = {
    fullName: '',
    civility: currentUser?.title,
    lastName: currentUser?.lastName,
    firstName: currentUser?.firstName,
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    postalCode: '',
    city: '',
    country: process.env.GATSBY_API_COUNTRY,
    phoneNumber: '',
    cellPhoneNumber: ''
  }
  const [user, setUser] = useState(customer)
  const websiteContext = useContext(WebsiteContext)

  const validationSchema = Yup.object({
    fullName: Yup.string()
      .max(20, 'Doit être de 20 caractères ou moins')
      .required('Ce champ est obligatoire'),
    firstName: Yup.string()
      .max(20, 'Doit être de 20 caractères ou moins')
      .required('Ce champ est obligatoire'),
    lastName: Yup.string()
      .max(20, 'Doit être de 20 caractères ou moins')
      .required('Ce champ est obligatoire'),
    addressLine1: Yup.string()
      .max(40, 'Doit être de 40 caractères ou moins')
      .required('Ce champ est obligatoire'),
    postalCode: Yup.string()
      .required('Ce champ est obligatoire')
      .matches(regExpZipCode[process.env.GATSBY_API_COUNTRY_CODE], {
        message: 'Veuillez indiquer un code postal valide.',
        excludeEmptyString: true
      }),
    city: Yup.string()
      .max(20, 'Doit être de 20 caractères ou moins')
      .required('Ce champ est obligatoire'),
    country: Yup.string()
      .max(20, 'Doit être de 20 caractères ou moins')
      .required('Ce champ est obligatoire'),
    phoneNumber: Yup.string()
      .matches(
        regExpPhoneNumber[process.env.GATSBY_API_COUNTRY_CODE],
        'Veuillez introduire un numéro de téléphone valide'
      )
      .test('oneOfRequired', 'Veuillez renseigner un des champs ci-dessus.', function () {
        return this.parent.phoneNumber || this.parent.cellPhoneNumber
      }),
    cellPhoneNumber: Yup.string()
      .matches(
        regExpPhoneNumber[process.env.GATSBY_API_COUNTRY_CODE],
        'Veuillez introduire un numéro de téléphone valide'
      )
      .test('oneOfRequired', 'Veuillez renseigner un des champs ci-dessus.', function () {
        return this.parent.phoneNumber || this.parent.cellPhoneNumber
      })
  })
  const enableInput = (content) => {
    const items = content?.map((item) => {
      if (item.name !== 'country') {
        item.disabled = false
        item.background = ''
      }
      return item
    })
    return items
  }

  const newAddress = () => {
    const newAddr = {
      fullName: user?.fullName,
      streetLine1: user?.addressLine1,
      streetLine2: user?.addressLine2,
      city: user?.city,
      postalCode: user?.postalCode,
      countryCode: process.env.GATSBY_API_COUNTRY_CODE,
      phoneNumber: user?.phoneNumber,
      defaultShippingAddress: true,
      customFields: {
        addressLine1: user?.addressLine1,
        addressLine2: user?.addressLine2,
        addressLine3: user?.addressLine3,
        addressLine4: user?.addressLine4,
        firstName: user?.firstName,
        lastName: user?.lastName,
        civility: user?.civility,
        cellPhoneNumber: user?.cellPhoneNumber
      }
    }
    return newAddr
  }
  //TODO better onchange handling
  const updateUser = (e) => {
    if (e && e.target) {
      setUser({ ...user, [e.target.name]: e.target.value })
    }
  }
  const formik = useFormik({
    initialValues: user,
    validationSchema: validationSchema,
    onSubmit: async () => {
      const address = newAddress()
      setLoading(true)
      await createCustomerAddress(address)
      setLoading(false)
    }
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="tsh-form">
        {sectionAdr.map((bloc, key) => {
          const content = enableInput(bloc.content)
          return (
            <Section
              key={`section-${key}`}
              title={bloc?.title}
              subTitle={bloc?.subTitle}
              updateUser={updateUser}
              content={content}
              formik={formik}
              displayLabel={displayLabel}
            />
          )
        })}
        <Flex className="buttons">
          <Button w="135px" type="reset" className="cancel" onClick={handleRead}>
            Annuler
          </Button>
          <Button
            w="135px"
            disabled={loading}
            bg={websiteContext.mainColor}
            _hover={{ bg: websiteContext.darkColor }}
            type="submit">
            {loading ? (
              <CircularProgress
                isIndeterminate={true}
                size={25}
                color={websiteContext?.mainColor}
              />
            ) : (
              'Enregistrer'
            )}
          </Button>
        </Flex>
      </form>
    </>
  )
}

export default NewAddress
