import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import * as Yup from 'yup'

// TODO handle country in schema
const regExpZipCode = {
  FR: /^(?:[0-8]\d|9[0-8])\d{3}$/,
  BE: /^(?:(?:[1-9])(?:\d{3}))$/,
  CH: /^[1-9]\d{3}$/
}

const regExpPhoneNumber = {
  FR: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  BE: /^((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$/,
  CH: /^(?:(?:|0{1,2}|\+{0,2})41(?:|\(0\))|0)([1-9]\d)(\d{3})(\d{2})(\d{2})$/
}
const regexPortableNumber = {
  FR: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  BE: /^((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3}$/,
  CH: /^(?:(?:|0{1,2}|\+{0,2})41(?:|\(0\))|0)([1-9]\d)(\d{3})(\d{2})(\d{2})$/
}
const formSettings = [
  {
    title: 'Mon identité',
    fields: [
      {
        label: 'Civilité',
        type: 'Radio',
        values: ['Madame', 'Monsieur'],
        attrKey: 'currentUser.title',
        readOnly: true,
        isRequired: true,
        hideLabel: false,
        validator: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
      },
      {
        label: 'Nom',
        type: 'Input',
        attrKey: 'currentUser.lastName',
        readOnly: true,
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'Prénom',
        type: 'Input',
        attrKey: 'currentUser.firstName',
        readOnly: true,
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      }
    ]
  },
  {
    title: 'Mon adresse de facturation',
    fields: [
      {
        label: 'Adresse',
        type: 'Input',
        placeholder: 'N° et voie (rue, allée, avenue, boulevard...)',
        attrKey: 'billingAddress.streetLine1',
        helper: '1 rue Victor Hugo',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(40, 'Doit être de 40 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        type: 'Input',
        placeholder: 'Appartement, étage, porte, couloir...',
        helper: 'Appt 25, Etage 3, Esc B',
        attrKey: 'billingAddress.streetLine2',
        validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        type: 'Input',
        placeholder: 'Bâtiment, résidence, entrée...',
        helper: 'Bât B, Rés Foch, Entrée B',
        attrKey: 'billingAddress.customFields.addressLine3',
        validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        type: 'Input',
        placeholder: 'Lieu-dit',
        helper: 'Bois Joly',
        attrKey: 'billingAddress.customFields.addressLine4',
        validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        label: 'Ville',
        type: 'Input',
        helper: 'Ex: PARIS',
        attrKey: 'billingAddress.city',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(30, 'Doit être de 30 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'Code postal',
        helper: 'Veuillez indiquer un code postal valide.',
        type: 'Input',
        attrKey: 'billingAddress.postalCode',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .required('Ce champ est obligatoire')
            .matches(regExpZipCode[process.env.GATSBY_API_COUNTRY_CODE], {
              message: 'Veuillez indiquer un code postal valide.',
              excludeEmptyString: true
            })
      },
      {
        label: 'Pays',
        type: 'Input',
        attrKey: 'billingAddress.country.name',
        readOnly: true,
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      }
    ]
  },
  {
    title: 'Téléphone',
    topContent:
      "Merci d’indiquer au moins un numéro valide (indispensable pour l'expédition de votre commande)",
    fields: [
      {
        label: 'Fixe',
        type: 'Input',
        helper: 'Ex: 01XXXXXXXX',
        attrKey: 'billingAddress.phoneNumber',
        validator: () => (current) =>
          Yup.string()
            .nullable()
            .test(
              'isSet',
              'Veuillez introduire un numéro de téléphone valide',
              (v) =>
                regExpPhoneNumber[process.env.GATSBY_API_COUNTRY_CODE].test(v) ||
                isNil(v) ||
                trim(v) === ''
            )
            .test(
              'at-least-one-property',
              'Veuillez renseigner au moins un numéro de téléphone.',
              (v) => !!(v || current?.billingAddress?.customFields?.cellPhoneNumber.trim())
            )
      },
      {
        label: 'Portable',
        helper: 'Ex: 06XXXXXXXX ou 07XXXXXXXX',
        type: 'Input',
        attrKey: 'billingAddress.customFields.cellPhoneNumber',
        validator: () => (current) =>
          Yup.string()
            .nullable()
            .test(
              'isSet',
              'Veuillez introduire un numéro de téléphone valide',
              (v) =>
                regexPortableNumber[process.env.GATSBY_API_COUNTRY_CODE].test(v) ||
                isNil(v) ||
                trim(v) === ''
            )
            .test(
              'at-least-one-property',
              'Veuillez renseigner au moins un numéro de téléphone.',
              (v) => !!(v || current?.billingAddress?.phoneNumber.trim())
            )
      }
    ]
  }
]

export default formSettings
