import { Flex, GatsbyImage, Link, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useEffect } from 'react'

import { DataLayerUtils } from '../context'

const CollectionFooter = (props) => {
  const { collection } = props

  useEffect(() => {
    if (collection?.customFields?.pushImage) {
      DataLayerUtils.addPromotionImpression({
        promotionName: `Push category ${collection.name}`,
        promotionCreative: collection?.customFields?.pushImage?.source,
        promotionPosition: 0,
        promotionId: collection.id
      })
    }
  }, [])

  return (
    <Flex w="100%" maxWidth="1000px" direction="column">
      {collection?.customFields?.pushImage && (
        <Flex w={{ base: '100%', md: '100%' }} align="baseline" justify="center" mt="30px">
          <Link
            to={collection?.customFields?.pushLink}
            style={{ width: '440px' }}
            onClick={() =>
              DataLayerUtils.addPromotionClick({
                promotionName: `Push category ${collection.name}`,
                promotionCreative: collection?.customFields?.pushImage?.source,
                promotionPosition: 0,
                promotionId: collection.id
              })
            }>
            <GatsbyImage
              image={collection?.customFields?.pushImage?.image?.childImageSharp?.gatsbyImageData}
              alt={collection?.name}
              style={{ width: '100%', borderRadius: '3px' }}
            />
          </Link>
        </Flex>
      )}
      <Flex justify={{ base: 'center', md: 'flex-start' }} mt="20px" mb="20px" w="100%">
        <Flex
          h="4px"
          w={{ base: '65%', md: '65px' }}
          backgroundColor={collection?.customFields?.color}
        />
      </Flex>
      <Text
        as="h2"
        fontSize="19.5px"
        fontWeight={700}
        pl={{ base: '10px', md: 0 }}
        pr={{ base: '10px', md: 0 }}
        color="#333333"
        fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
        dangerouslySetInnerHTML={{ __html: collection?.customFields?.footerTitle }}></Text>
      <Text
        mt="5px"
        textAlign="justify"
        w="100%"
        pl={{ base: '10px', md: 0 }}
        pr={{ base: '10px', md: 0 }}
        fontSize="13px"
        fontWeight={400}
        color="#333333"
        fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
        dangerouslySetInnerHTML={{ __html: collection?.customFields?.footerDescription }}></Text>
    </Flex>
  )
}

export default memo(CollectionFooter)
