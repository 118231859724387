import './menu.css'

import {
  Box,
  Button,
  chroma,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  GatsbyImage,
  Link,
  ListItem,
  Picto,
  Text,
  UnorderedList,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import React, { Fragment, memo, useContext, useEffect, useState } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'
import { WatchTVShowButton } from '../tv'

const Push = (props) => {
  const { collection, active, debouncedSetActive, closeOnClick } = props

  useEffect(() => {
    if (active && collection?.customFields?.pushImage) {
      DataLayerUtils.addPromotionImpression({
        promotionName: `Push category ${collection?.name}`,
        promotionCreative: collection?.customFields?.pushImage?.source,
        promotionPosition: 0,
        promotionId: collection?.id
      })
    }
  }, [active])

  if (!collection?.customFields?.pushImage) {
    return null
  }
  return (
    <Link
      to={collection?.customFields?.pushLink}
      onClick={() => {
        debouncedSetActive(false)
        closeOnClick(false, 0)
        DataLayerUtils.addPromotionClick({
          promotionName: `Push category ${collection?.name}`,
          promotionCreative: collection?.customFields?.pushImage?.source,
          promotionPosition: 0,
          promotionId: collection?.id
        })
      }}>
      <Flex
        sx={{
          '& > div': {
            pointerEvents: 'none'
          }
        }}>
        <GatsbyImage
          image={collection?.customFields?.pushImage?.image?.childImageSharp?.gatsbyImageData}
          alt={collection?.name}
        />
      </Flex>
    </Link>
  )
}

const MenuItem = (props) => {
  const { collection, debouncedSetActive, activeMenu } = props
  const websiteContext = useContext(WebsiteContext)
  const [activeDropDown, setActiveDropDown] = useState(false)
  const debouncedSetActiveDropDown = debounce(setActiveDropDown, 300)
  const [closeMenu, setCloseMenu] = useState(true)

  const closeOnClick = (value, timeout) => {
    setTimeout(() => {
      setCloseMenu(value)
    }, timeout)
  }

  return (
    <Box
      className={`main-item collection ${activeMenu ? 'open' : ''}`}
      alignItems="center"
      _hover={{ backgroundColor: websiteContext?.mainColor }}
      transition="all .3s ease"
      display={{ base: 'none', md: 'flex' }}
      onMouseEnter={() => {
        debouncedSetActive(true)
        debouncedSetActiveDropDown(true)
      }}
      onMouseLeave={() => {
        debouncedSetActiveDropDown(false)
        closeOnClick(true, 300)
      }}>
      <ListItem className="main-item-li" h="100%">
        <Box
          style={{
            display: 'flex',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'PT Sans, Arial, sans-serif',
            fontSize: '16px',
            fontWeight: 700,
            color: collection?.customFields?.menuColor
          }}>
          <Link
            to={collection?.customFields?.hasLink ? collection?.path : null}
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 15px'
            }}
            onClick={() => {
              debouncedSetActive(false)
              debouncedSetActiveDropDown(false)
              closeOnClick(true, 300)
              DataLayerUtils.addEvent({
                event: 'qwampEventLab',
                eventCat: 'Header',
                eventAct: 'Départ mega menu',
                eventLab: collection?.path
              })
            }}>
            {collection?.name}
          </Link>
        </Box>
      </ListItem>
      {!isEmpty(collection?.children) && (
        <Box className={`drop-down ${activeDropDown && closeMenu ? 'active' : ''}`}>
          <div
            style={{ backgroundColor: websiteContext?.mainColor, height: '2px', width: '100%' }}
          />
          <Flex direction="column" p="20px" h="220px">
            <Flex direction="row">
              <Flex w="50%" direction="row" wrap="wrap" align="space-between">
                {orderBy(
                  collection?.children.filter(
                    (c) => c.totalActiveProducts > 0 && c?.customFields?.includeInMenu),
                  ['position'],
                  ['asc']
                ).map((c, i) => (
                  <Link
                    to={c?.customFields?.hasLink ? c?.path : null}
                    key={'submenu-' + i}
                    style={{
                      width: '50%',
                      minHeight: '39px',
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'flex-start',
                      paddingLeft: 0
                    }}
                    onClick={() => {
                      debouncedSetActive(false)
                      closeOnClick(false, 0)
                      DataLayerUtils.addEvent({
                        event: 'qwampEventLab',
                        eventCat: 'Header',
                        eventAct: 'Départ mega menu',
                        eventLab: c?.path
                      })
                    }}>
                    <Flex>
                      <Box
                        className="arrow-collection"
                        height="0"
                        mt="8px"
                        borderTop="5px solid transparent"
                        borderRight="5px solid transparent"
                        borderBottom="5px solid transparent"
                        borderLeft={
                          c?.customFields?.menuColor
                            ? `5px solid ${c?.customFields?.menuColor}`
                            : `5px solid ${websiteContext?.mainColor}`
                        }
                      />

                      {c?.customFields?.menuImage?.image ? (
                        <Flex
                          position="relative"
                          top="-6px"
                          sx={{
                            '& > div': {
                              pointerEvents: 'none'
                            }
                          }}>
                          <GatsbyImage
                            image={
                              c?.customFields?.menuImage?.image?.childImageSharp?.gatsbyImageData
                            }
                            alt={collection?.name}
                          />
                        </Flex>
                      ) : (
                        <ListItem
                          style={{
                            listStyle: 'none',
                            lineHeight: '24px',
                            color: c?.customFields?.isMenuHighlighted
                              ? websiteContext?.mainColor
                              : '#000000',
                            fontWeight: c?.customFields?.isMenuHighlighted ? '600' : 'normal',
                            fontFamily: 'PT Sans Narrow, Arial, sans-serif',
                            fontSize: '15.5px',
                            padding: '0 8px'
                          }}
                          _hover={{ textDecoration: 'underline' }}>
                          {c?.name}
                        </ListItem>
                      )}
                    </Flex>
                  </Link>
                ))}
              </Flex>
              <Flex w="50%" align="baseline" justify="center">
                <Push
                  collection={collection}
                  active={activeDropDown}
                  debouncedSetActive={debouncedSetActive}
                  closeOnClick={closeOnClick}
                />
              </Flex>
            </Flex>
          </Flex>
          {!isEmpty(collection?.customFields?.topSelection) && (
            <Flex
              direction="column"
              align="center"
              style={{
                width: '100%',
                borderTop: '1px solid #dedede',
                backgroundColor: '#f5f5f5',
                paddingTop: '20px',
                paddingBottom: '20px',
                height: '87px'
              }}>
              <span
                style={{
                  color: websiteContext?.mainColor,
                  fontsize: '16px',
                  fontWeight: 700,
                  fontFamily: 'PT Sans, Arial, sans-serif',
                  textTransform: 'uppercase'
                }}>
                Notre top selection
              </span>
              <Flex w="100%" justify="space-evenly">
                {collection?.customFields?.topSelection?.map((product) => (
                  <Link
                    key={`top_selection_${product?.id}`}
                    to={`/${product?.customFields?.urlKey}`}
                    style={{
                      fontFamily: 'PT Sans Narrow, Arial, sans-serif',
                      fontSize: '15.5px',
                      fontWeight: 400,
                      paddingLeft: '20px',
                      paddingRight: '20px'
                    }}
                    className="top-selection-product">
                    {product?.name}{' '}
                    {`- ${capitalize(get(product, 'customFields.searchKeywords.0', '').trim())}`}
                  </Link>
                ))}
              </Flex>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  )
}

const MenuItemMobile = (props) => {
  const { collection, debouncedSetActive, onCloseMainMenu } = props

  const { isOpen, onClose, onOpen } = useDisclosure()
  const websiteContext = useContext(WebsiteContext)

  return (
    <Box
      display={{ base: 'block', md: 'none' }}
      onMouseEnter={() => debouncedSetActive(true)}
      onMouseLeave={() => debouncedSetActive(false)}
      justifyContent="initial">
      {collection?.children && !isEmpty(collection?.children) && collection?.totalActiveProducts > 0 && (
        <>
          <Button
            onClick={onOpen}
            _focus={{ outline: 'none' }}
            fontFamily="PT Sans, Arial, sans-serif"
            w="100%"
            variant="unstyled"
            h="38px"
            display={['flex', 'flex', 'flex', 'none']}
            p={{ base: '12px 0 12px 7px', md: '16px 0 16px 8px' }}
            borderBottom="1px"
            borderColor="#eef4ff"
            borderStyle="solid"
            justifyContent="initial">
            <Text fontSize="12.5px" fontWeight="700">
              {collection?.name}
            </Text>
            <Picto
              style={{
                position: 'absolute',
                right: '15px'
              }}
              icon="chevronRight"
              height="12px"
              width="12px"
            />
          </Button>
          <Drawer placement="left" onClose={onCloseMainMenu} isOpen={isOpen}>
            <DrawerOverlay bg="transparent">
              <Button
                position="absolute"
                top="0"
                right="0"
                w="40px"
                variant="unstyled"
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                  onClose()
                  onCloseMainMenu()
                }}>
                <Picto
                  icon="cross"
                  width="14px"
                  height="14px"
                  color="#fff"
                  style={{ margin: 'auto' }}
                />
              </Button>
            </DrawerOverlay>
            <DrawerContent flexDirection="row" maxW="90% !important">
              <DrawerBody p="0" w="85vw !important">
                <Button
                  onClick={onClose}
                  variant="unstyled"
                  display="flex"
                  fontFamily="PT Sans, Arial, sans-serif"
                  fontSize="12.5px"
                  fontWeight="700"
                  bg={websiteContext?.mainColor}
                  color="#fff"
                  w="100%"
                  h="38px"
                  borderRadius="none"
                  justifyContent="initial"
                  p="0 27px"
                  _focus={{ boxShadow: 'none' }}>
                  <Picto
                    style={{
                      position: 'absolute',
                      left: '4px'
                    }}
                    icon="chevronLeft"
                    height="12px"
                    width="12px"
                    color="#fff"
                  />
                  Retour
                </Button>
                <Box
                  display="flex"
                  p="12.5px 0 12.5px 8px"
                  borderBottom="1px"
                  borderColor="#eef4ff"
                  borderStyle="solid">
                  <Link
                    style={{ width: '100%' }}
                    to={collection?.customFields?.hasLink ? collection?.path : null}
                    onClick={() => {
                      DataLayerUtils.addEvent({
                        event: 'qwampEventLab',
                        eventCat: 'Header',
                        eventAct: 'Départ mega menu',
                        eventLab: collection?.path
                      })
                      onClose()
                      onCloseMainMenu()
                    }}>
                    <Text
                      color={websiteContext?.mainColor}
                      fontSize="12.5px"
                      fontWeight="700"
                      lineHeight="1"
                      fontFamily="PT Sans, Arial, sans-serif">
                      {collection?.name}
                    </Text>
                  </Link>
                </Box>
                {orderBy(
                  collection?.children?.filter(
                    (c) => c.totalActiveProducts > 0 && c?.customFields?.includeInMenu
                  ),
                  ['position'],
                  ['asc']
                ).map((c, i) => (
                  <Box
                    position="relative"
                    display="flex"
                    p="12.5px 0 12.5px 8px"
                    borderBottom="1px"
                    borderColor="#eef4ff"
                    borderStyle="solid"
                    key={'submenu-mobile-' + i}>
                    <Link
                      style={{ width: '100%' }}
                      to={c?.customFields?.hasLink ? c?.path : null}
                      display="flex"
                      onClick={() => {
                        DataLayerUtils.addEvent({
                          event: 'qwampEventLab',
                          eventCat: 'Header',
                          eventAct: 'Départ mega menu',
                          eventLab: c?.path
                        })
                        onClose()
                        onCloseMainMenu()
                      }}>
                      {c?.customFields?.menuImage?.image ? (
                        <GatsbyImage
                          image={
                            c?.customFields?.menuImage?.image?.childImageSharp?.gatsbyImageData
                          }
                          alt={collection?.name}
                        />
                      ) : (
                        <Text
                          fontFamily="PT Sans, Arial, sans-serif"
                          fontSize="12.5px"
                          fontWeight="700"
                          lineHeight="1"
                          color={
                            c?.customFields?.isMenuHighlighted
                              ? websiteContext?.mainColor
                              : '#000000'
                          }>
                          {c?.name}
                        </Text>
                      )}
                    </Link>
                    <Picto
                      style={{
                        top: c?.customFields?.menuImage?.image ? '25px' : '',
                        position: 'absolute',
                        right: '15px'
                      }}
                      color={
                        c?.customFields?.isMenuHighlighted ? websiteContext?.mainColor : '#000000'
                      }
                      icon="chevronRight"
                      height="12px"
                      width="12px"
                    />
                  </Box>
                ))}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
      {!collection?.children && collection?.totalActiveProducts > 0 && (
        <ListItem className="main-item-li" pr="15px" pl="15px">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Link
              to={collection?.customFields?.hasLink ? collection?.path : null}
              onClick={() => {
                DataLayerUtils.addEvent({
                  event: 'qwampEventLab',
                  eventCat: 'Header',
                  eventAct: 'Départ mega menu',
                  eventLab: collection?.path
                })
                onClose()
                onCloseMainMenu()
              }}>
              {collection?.name}
            </Link>
          </div>
        </ListItem>
      )}
    </Box>
  )
}

const MemoizedMenuItem = memo(MenuItem)
const MemoizedMenuItemMobile = memo(MenuItemMobile)

const Menu = (props) => {
  const {
    collections = [],
    push,
    blogLabel,
    storeLabel,
    landingPages = [],
    header,
    showPartner = false,
    showCatalog = false,
    catalogLink
  } = props
  const websiteContext = useContext(WebsiteContext)
  const [active, setActive] = useState(false)

  const debouncedSetActive = debounce(setActive, 300)
  const {
    isOpen: isOpenMainMenu,
    onOpen: onOpenMainMenu,
    onClose: onCloseMainMenu
  } = useDisclosure()

  useEffect(() => {
    if (!websiteContext?.isMobile) {
      onCloseMainMenu()
    }
  }, [websiteContext?.isMobile])

  const listItems = (
    <UnorderedList
      listStyleType="none"
      position="relative"
      top="0"
      w="100%"
      h="46px"
      m="0"
      textAlign="center"
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      style={{ justifyContent: 'space-between' }}>
      {collections?.map((collection, i) => (
        <Fragment key={'menu-' + i}>
          <MemoizedMenuItemMobile
            collection={collection}
            debouncedSetActive={debouncedSetActive}
            onCloseMainMenu={onCloseMainMenu}
          />
          <MemoizedMenuItem
            collection={collection}
            className="menu-item"
            push={push}
            debouncedSetActive={debouncedSetActive}
            activeMenu={active}
          />
        </Fragment>
      ))}
      {blogLabel && (
        <Flex
          alignItems="center"
          className="main-item"
          display={{ base: 'block', md: 'flex' }}
          onMouseEnter={() => debouncedSetActive(false)}
          onMouseLeave={() => debouncedSetActive(true)}
          _hover={{
            backgroundColor: websiteContext?.mainColor,
            '& span': { color: '#fff' }
          }}>
          <ListItem h="100%" p={{ base: '11px 8px', md: '0' }} className="menu-item-li">
            <Flex
              className="blog-menu"
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: websiteContext?.isMobile ? 'flex-start' : 'center',
                padding: websiteContext?.isMobile ? '0' : '0 15px',
                fontFamily: 'satisfy, cursive'
              }}
              fontSize={{ base: '18px', md: '19px' }}
              onClick={onCloseMainMenu}>
              <a href="/blog" fontSize={{ base: '18px', md: '19px' }}>
                <Text as="span" _first={{ color: websiteContext?.mainColor }}>{`${
                  blogLabel.split(' ')[0]
                }`}</Text>
                &nbsp;
                <Text as="span">{`${blogLabel.split(' ').slice(1).join(' ')}`}</Text>
              </a>
            </Flex>
          </ListItem>
        </Flex>
      )}
      <Flex
        alignItems="center"
        justify="flex-start"
        className="main-item"
        display={{ base: 'block', md: 'flex' }}
        onMouseEnter={() => debouncedSetActive(false)}
        _hover={{ backgroundColor: '#f6ded7' }}
        transition="all .9s ease"
        onMouseLeave={() => debouncedSetActive(true)}>
        {landingPages?.map((lp, i) => (
          <ListItem
            key={`lp_${i}`}
            p={{ base: '10px 8px', md: '12px 15px' }}
            className="menu-item-li">
            <Link to={`/${lp.path}`} className="blog-menu" onClick={onCloseMainMenu}>
              <Flex w={{ base: '90px', md: 'auto' }}>
                {get(lp, `menuLogo.image.childImageSharp.gatsbyImageData`) && (
                  <GatsbyImage
                    image={get(lp, `menuLogo.image.childImageSharp.gatsbyImageData`)}
                    loading="eager"
                    alt={get(lp, 'path', '')}
                  />
                )}
              </Flex>
            </Link>
          </ListItem>
        ))}
      </Flex>
      <Box
        bg={chroma(websiteContext?.lightColor).alpha(0.1).hex()}
        display={{ base: 'block', md: 'none' }}
        sx={{
          li: {
            fontSize: '14px',
            fontFamily: 'PT Sans, Arial, sans-serif',
            textAlign: 'left',
            padding: '14px 8px',
            borderBottom: 'solid 1px #fff',
            width: '100%',
            '& a': {
              display: 'flex',
              width: '100%'
            }
          }
        }}>
        {header?.displayTVShows && (
          <ListItem color="#add">
            <WatchTVShowButton tvShowSettings={header?.tvShowSettings} />
          </ListItem>
        )}
        {header?.displayTVChannel && (
          <ListItem>
            <Link to={header?.tvChannelLink} onClick={onCloseMainMenu}>
              {header?.tvChannelLabel}
            </Link>
          </ListItem>
        )}
        {showCatalog && (
          <ListItem>
            <Link to={catalogLink} onClick={onCloseMainMenu}>
              Commande catalogue
            </Link>
          </ListItem>
        )}
        {storeLabel && (
          <ListItem>
            <Link to="/points-de-vente" onClick={onCloseMainMenu}>
              {storeLabel}
            </Link>
          </ListItem>
        )}
        {showPartner && (
          <ListItem>
            <Link to="/enseignes-partenaires" onClick={onCloseMainMenu}>
              Nos enseignes partenaires
            </Link>
          </ListItem>
        )}
        <ListItem>
          <Link to="/fidelite" onClick={onCloseMainMenu}>
            La carte fidélité {websiteContext?.title}
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/customer/newsletter/subscribe" onClick={onCloseMainMenu}>
            Inscrivez-vous à la newsletter
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/contacts" onClick={onCloseMainMenu}>
            Nous contacter
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/customer/account" onClick={onCloseMainMenu}>
            Suivi de commande
          </Link>
        </ListItem>
      </Box>
    </UnorderedList>
  )

  return (
    <>
      <nav
        onMouseEnter={() => debouncedSetActive(true)}
        onMouseLeave={() => debouncedSetActive(false)}
        className={`menu-nav ${active ? 'menu-active' : ''}`}>
        <Box display={{ base: 'none', md: 'flex' }}>{listItems}</Box>
        <Button
          onClick={onOpenMainMenu}
          variant="unstyled"
          _focus={{ boxShadow: 'none' }}
          display={{ base: 'flex', md: 'none' }}>
          <Picto
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
            icon="burgerMenu"
            width="34px"
            height="34px"
            mt="10px"
            mr="10px"
          />
        </Button>
        <Drawer
          className="mainDrawer"
          placement="left"
          onClose={onCloseMainMenu}
          isOpen={isOpenMainMenu}>
          <DrawerOverlay bg="rgba(51, 51, 51, .8)">
            <Button
              position="absolute"
              top="0"
              right="0"
              w="40px"
              variant="unstyled"
              minWidth="auto"
              alignItems="center"
              justifyContent="center"
              onClick={onCloseMainMenu}>
              <Picto
                icon="cross"
                width="14px"
                height="14px"
                color="#fff"
                style={{ margin: 'auto' }}
              />
            </Button>
          </DrawerOverlay>
          <DrawerContent flexDirection="row" maxW="90% !important" position="relative">
            <DrawerBody p="0" w="85vw !important">
              {listItems}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </nav>
      <Box
        display={{ base: 'none', md: 'block' }}
        left="0"
        w="100vw"
        position="absolute"
        h={active ? '100vh' : 0}
        opacity={active ? '.5' : '0'}
        bg="#000"
        transition="opacity .5s linear"
      />
    </>
  )
}

export default Menu
