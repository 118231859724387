import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  GatsbyImage,
  Input,
  InputGroup,
  InputRightElement,
  Picto,
  Text,
  useDisclosure,
  useIsMobile,
  useOutsideClick
} from '@stars-ecom/shared-atoms-ui'
import Fuse from 'fuse.js'
import { navigate } from 'gatsby'
import take from 'lodash/take'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'

const AutoCompleteItem = (props) => {
  const { product, term, setTerm = () => {}, selected = false } = props

  const onClick = () => {
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'Search',
      eventAct: 'Départ produit',
      eventLab: product?.externalCode
    })
    setTerm('')
    navigate(`/${product?.path}`)
  }

  return (
    <Flex
      direction="row"
      onClick={onClick}
      align="center"
      justify="flex-start"
      backgroundColor={selected ? '#f5f5f5' : '#ffffff'}
      width="100%"
      style={{
        height: '70px',
        borderLeft: '1px solid #c5c5c5',
        borderRight: '1px solid #c5c5c5',
        borderBottom: '1px solid #c5c5c5',
        padding: '10px',
        cursor: 'pointer'
      }}>
      <Box style={{ width: '65px', height: '45px' }} mr="16px">
        {product?.image && (
          <GatsbyImage
            style={{ width: '65px', height: '45px' }}
            alt={product?.name}
            title={product?.name}
            image={product?.image?.childImageSharp?.gatsbyImageData}
          />
        )}
      </Box>
      <Text
        fontSize={{ base: '14px', md: '18px' }}
        fontWeight={{ base: '400', md: '700' }}
        fontFamily="PT Sans, Arial, sans-serif"
        textAlign="left"
        textTransform="uppercase"
        color="#999"
        dangerouslySetInnerHTML={{
          __html: product?.name
            ?.toUpperCase()
            .replace(term.toUpperCase(), `<span style="color:#666676">${term}</span>`)
        }}></Text>
    </Flex>
  )
}

const SearchBar = (props) => {
  const { placeholder = 'Votre recherche...' } = props
  const [term, setTerm] = useState('')
  const [autocomplete, setAutocomplete] = useState([])
  const [autocompleteList, setAutocompleteList] = useState([])
  const [noResult, setNoResult] = useState(false)
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false)
  const [cursor, setCursor] = useState(-1)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const ref = useRef()
  const inputRef = useRef()
  const isMobile = useIsMobile()
  const MAX_ITEMS = 4
  const websiteContext = useContext(WebsiteContext)

  useOutsideClick({
    ref: ref,
    handler: () => {
      if (!isMobile) {
        closeAutocomplete()
      }
    }
  })

  const closeAutocomplete = () => {
    setIsAutocompleteOpen(false)
    setCursor(-1)
  }

  const getData = async () => {
    fetch(`/autocomplete.json`)
      .then((res) => res.json())
      .then(
        (res) => {
          setAutocomplete(res)
        },
        (e) => {
          console.error(e)
        }
      )
  }

  useEffect(() => {
    getData()
  }, [])

  const url = typeof window !== 'undefined' ? window.location.href : ''
  useEffect(() => {
    closeAutocomplete()
    onClose()
  }, [url])

  const doSearch = async (e) => {
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > 0) {
      setCursor(cursor - 1)
    } else if (e.keyCode === 40 && cursor < MAX_ITEMS - 1 && cursor < autocompleteList.length - 1) {
      setCursor(cursor + 1)
    }

    if (e.key === 'Enter') {
      if (cursor < 0) {
        if (!e.preventAnalytic) {
          console.log('Lancer requête')
          DataLayerUtils.addEvent({
            event: 'qwampEventLab',
            eventCat: 'Search',
            eventAct: 'Lancer requête',
            eventLab: term
          })
        }
        onClose()
        setTerm('')
        closeAutocomplete()
        navigate(`/search/?q=${term}`)
      } else {
        const product = autocompleteList[cursor]
        DataLayerUtils.addEvent({
          event: 'qwampEventLab',
          eventCat: 'Search',
          eventAct: 'Départ produit',
          eventLab: product?.externalCode
        })
        onClose()
        setTerm('')
        closeAutocomplete()
        navigate(`/${product?.path}`)
      }
    }
  }

  useEffect(() => {
    if (term?.trim().length >= 3) {
      const options = {
        threshold: 0.2,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 3,
        keys: ['name']
      }
      const fuse = new Fuse(autocomplete, options)
      const result = fuse.search(term).map((object) => object.item)
      if (result?.length === 0) {
        setNoResult(true)
        setAutocompleteList([])
      } else {
        setNoResult(false)
        setAutocompleteList(result)
      }
    } else {
      setNoResult(false)
      setAutocompleteList([])
    }
  }, [term])

  useEffect(() => {
    if (autocompleteList.length > 0 || noResult) {
      setIsAutocompleteOpen(true)
    } else {
      closeAutocomplete()
    }
  }, [autocompleteList, noResult])

  const styleSearchInput = {
    padding: '4px 44px 4px 14px',
    fontStyle: 'italic',
    fontSize: '14px',
    fontWeight: '400',
    color: '#8e94a2',
    border: '1px solid #cfd5e3',
    borderRadius: '5px',
    boxShadow: 'none'
  }

  return (
    <Box w="100%" ref={ref}>
      <Button
        onClick={onOpen}
        variant="unstyled"
        _focus={{ boxShadow: 'none' }}
        display={{ base: 'flex', md: 'none' }}>
        <Picto
          icon="search"
          width="28px"
          height="28px"
          color={isOpen ? websiteContext?.mainColor : '#333'}
        />
      </Button>
      <Drawer
        placement="top"
        onClose={onClose}
        isOpen={isOpen}
        initialFocusRef={inputRef}
        closeOnOverlayClick={true}
        onOverlayClick={() => closeAutocomplete()}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p="8px 12px" overflow="hidden">
            <Flex>
              <InputGroup>
                <Input
                  ref={inputRef}
                  placeholder={placeholder}
                  pattern="[A-Za-z0-9\s]{3,40}"
                  h="50px !important"
                  style={styleSearchInput}
                  value={term}
                  onClick={(e) => console.log('click', e)}
                  onFocusCapture={(e) => console.log('capture', e)}
                  onFocus={(e) => {
                    console.log('Demarrage fonction', e)
                    DataLayerUtils.addEvent({
                      event: 'qwampEvent',
                      eventCat: 'Search',
                      eventAct: 'Démarrage fonction'
                    })
                  }}
                  onChange={(e) => setTerm(e.target.value)}
                  onKeyDown={doSearch}
                  zIndex="10000"
                />
                <InputRightElement
                  right="5px"
                  zIndex="10000"
                  // eslint-disable-next-line react/no-children-prop
                  children={
                    <Box
                      mt="10px"
                      mr="10px"
                      _focus={{ outline: 'none' }}
                      onClick={() => {
                        doSearch({ key: 'Enter' })
                      }}>
                      <Picto
                        icon="search"
                        width="24px"
                        height="24px"
                        color={isAutocompleteOpen ? websiteContext?.mainColor : '#333333'}
                      />
                    </Box>
                  }
                />
              </InputGroup>
              <Box ml="10px">
                <Button
                  variant="unstyled"
                  minWidth="auto"
                  pr="10px"
                  onClick={() => {
                    closeAutocomplete()
                    onClose()
                  }}>
                  <Picto icon="cross" width="14px" height="14px" />
                </Button>
              </Box>
            </Flex>
          </DrawerBody>

          <Flex>
            {isAutocompleteOpen && (
              <AutoCompleteContent
                autocompleteList={autocompleteList}
                noResult={noResult}
                term={term}
                setIsAutocompleteOpen={setIsAutocompleteOpen}
                setTerm={setTerm}
                onClose={onClose}
                doSearch={doSearch}
                selectedItem={cursor}
                maxItems={MAX_ITEMS}
              />
            )}
          </Flex>
        </DrawerContent>
      </Drawer>

      <InputGroup height="auto" display={{ base: 'none', md: 'block' }} maxWidth="405px">
        <Input
          placeholder={placeholder}
          pattern="[A-Za-z0-9\s]{3,40}"
          h="50px !important"
          style={styleSearchInput}
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          onKeyDown={doSearch}
        />
        <InputRightElement
          // eslint-disable-next-line react/no-children-prop
          children={
            <Box
              mt="10px"
              mr="10px"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                doSearch({ key: 'Enter' })
              }}>
              <Picto
                icon="search"
                width="26px"
                height="26px"
                color={isAutocompleteOpen ? websiteContext?.mainColor : '#333333'}
              />
            </Box>
          }
        />
      </InputGroup>
      {!isOpen && isAutocompleteOpen && (
        <AutoCompleteContent
          autocompleteList={autocompleteList}
          noResult={noResult}
          term={term}
          setIsAutocompleteOpen={setIsAutocompleteOpen}
          setTerm={setTerm}
          onClose={onClose}
          doSearch={doSearch}
          selectedItem={cursor}
        />
      )}
    </Box>
  )
}

const AutoCompleteContent = (props) => {
  const {
    autocompleteList,
    noResult,
    term,
    closeAutocomplete = () => {},
    onClose = () => {},
    doSearch = () => {},
    setTerm = () => {},
    selectedItem = 0,
    maxItems = 4
  } = props

  const websiteContext = useContext(WebsiteContext)

  return (
    <Box
      width={{ base: '100%', md: '405px' }}
      position="relative"
      maxH={noResult ? '70px' : '350px'}>
      <Flex
        backgroundColor="#ffffff"
        top="0"
        direction="column"
        position="absolute"
        zIndex="1000"
        width={{ base: '100%', md: '405px' }}>
        {!noResult ? (
          <>
            {take(autocompleteList, maxItems).map((p, i) => (
              <AutoCompleteItem
                key={Math.random()}
                product={p}
                term={term}
                selected={i === selectedItem}
                closeFunction={() => {
                  closeAutocomplete()
                  onClose()
                }}
                setTerm={setTerm}
              />
            ))}

            <Flex
              direction="row"
              align="center"
              justify="flex-end"
              style={{
                height: '66px',
                borderLeft: '1px solid #c5c5c5',
                borderRight: '1px solid #c5c5c5',
                borderBottom: '1px solid #c5c5c5',
                padding: '20px'
              }}>
              <Button
                mr="3px"
                fontFamily="PT Sans Narrow, arial, sans-serif"
                fontSize="18px"
                fontWeight={400}
                onClick={() => {
                  console.log('Voir tous les résultats')
                  DataLayerUtils.addEvent({
                    event: 'qwampEventLab',
                    eventCat: 'Search',
                    eventAct: 'Voir tous les résultats',
                    eventLab: term
                  })
                  doSearch({ key: 'Enter', preventAnalytic: true })
                }}
                bg={websiteContext?.mainColor}
                border="none"
                color="#fff"
                borderRadius="0"
                _hover={{ bg: websiteContext?.mainColor, border: 'none' }}
                _active={{
                  bg: websiteContext?.mainColor,
                  border: 'none'
                }}>
                Voir tous les résultats
              </Button>
            </Flex>
          </>
        ) : (
          <Flex
            direction="row"
            onClick={() => {
              closeAutocomplete()
              onClose()
            }}
            align="center"
            justify="center"
            height={{ base: '70px', md: '66px' }}
            borderLeft={{ base: '0px', md: '1px solid #c5c5c5' }}
            borderRight={{ base: '0px', md: '1px solid #c5c5c5' }}
            borderBottom={{ base: '0px', md: '1px solid #c5c5c5' }}
            p="10px"
            cursor="pointer">
            <Text
              fontSize="14px"
              fontWeight={400}
              fontFamily="PT Sans, Arial, sans-serif"
              textAlign="center"
              color="#333333">
              Aucun résultat ne correspond à votre recherche
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export default SearchBar
