import { axios, updateOrder } from '@stars-ecom/shared-organisms-ui'
import { navigate } from 'gatsby'
import debounce from 'lodash/debounce'
import get from 'lodash/get'

const addItemToOrder = async (productVariantId, quantity, type = 'STANDARD', parentOrderLine) => {
  try {
    const customFields = { type }
    if (parentOrderLine) {
      customFields.parentOrderLine = parentOrderLine
    }
    console.log('addItemToOrder rrequest', { productVariantId, quantity, customFields })
    const result = await axios.post(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/addItemToOrder`,
      { productVariantId, quantity, customFields }
    )
    console.log('addItemToOrder Result', result)
    const data = get(result, 'data', {})
    if (data.__typename === 'Order') {
      updateOrder(result.data)
    } else {
      // OrderModificationError | OrderLimitError | NegativeQuantityError
      console.error(result)
    }
    return data
  } catch (e) {
    await getActiveOrder()
    console.error(e)
    return {}
  }
}

const removeOrderLine = async (orderLineId) => {
  try {
    const result = await axios.post(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/adjustOrderLine`,
      { orderLineId, quantity: 0 }
    )
    console.log('removeOrderLine Result', result)
    if (result.data.__typename === 'Order') {
      updateOrder(result.data)
      return get(result, 'data', {})
    }
  } catch (e) {
    await getActiveOrder()
    console.error(e)
    return {}
  }
}

const validateCartInventory = async () => {
  try {
    const result = await axios.post(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/validateCartInventory`
    )
    console.log('validateCartInventory Result', result)
    if (result.data.__typename === 'Order') {
      updateOrder(result.data)
      return get(result, 'data', {})
    } else if (result.data.__typename === 'CartInventoryError') {
      updateOrder(result.data.order)
      return get(result, 'data', {})
    }
  } catch (e) {
    await getActiveOrder()
    console.error(e)
    return {}
  }
}

const adjustOrderLine = async (orderLineId, quantity) => {
  try {
    const result = await axios.post(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/adjustOrderLine`,
      { orderLineId, quantity }
    )
    console.log('adjustOrderLine Result', result)
    if (result.data.__typename === 'Order') {
      updateOrder(result.data)
      return get(result, 'data', {})
    } else {
      await getActiveOrder()
      return get(result, 'data', {})
    }
  } catch (e) {
    await getActiveOrder()
    console.error(e)
  }
}

const getActiveOrder = async () => {
  try {
    const result = await axios.get(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getActiveOrder`
    )
    console.log('getActiveOrder Result', result)
    if (result.data.__typename === 'Order') {
      updateOrder(result.data)
      return get(result, 'data', {})
    } else {
      updateOrder({})
    }
  } catch (e) {
    console.error(e)
    return {}
  }
}

const applyCouponCode = async (couponCode) => {
  try {
    const result = await axios.post(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/applyCouponCode`,
      { couponCode: couponCode?.toUpperCase() }
    )
    console.log('applyCouponCode Result', result)
    if (result.data.__typename === 'Order') {
      updateOrder(result.data)
      return get(result, 'data', {})
    } else {
      return {
        __typename: result.data.__typename,
        errorCode: result.data.error || result.data.errorCode
      }
    }
  } catch (e) {
    console.error(e)
    return {}
  }
}

const removeCouponCode = async (couponCode) => {
  try {
    const result = await axios.post(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/removeCouponCode`,
      { couponCode }
    )
    console.log('removeCouponCode Result', result)
    if (result.data.__typename === 'Order') {
      updateOrder(result.data)
      return get(result, 'data', {})
    }
  } catch (e) {
    console.error(e)
    return {}
  }
}

const addPaymentToOrder = async (method, metadata, saveCard = false) => {
  try {
    const result = await axios.post(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/addPaymentToOrder`,
      { input: { method, metadata }, saveCard }
    )
    console.log('addPaymentToOrder Result', result)
    if (result.data.__typename === 'Order') {
      debounce(updateOrder, 200)({})
      return get(result, 'data', {})
    }

    //Redirect to failure payment page
    const paymentErrorCodes = ['PAYMENT_FAILED_ERROR', 'ORDER_PAYMENT_STATE_ERROR']
    if (paymentErrorCodes.includes(result.data.errorCode)) {
      navigate('/checkout/payment/failure')
      return {}
    }

    if (result.data.errorCode === 'PAYMENT_DECLINED_ERROR') {
      return get(result, 'data', {})
    }
  } catch (e) {
    console.error(e)
    return {}
  }
}

const validate3ds = async (transactionId, state) => {
  try {
    const result = await axios.post(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/payments/${transactionId}/3ds/${state}`
    )
    //console.log('validate3ds Result', result)

    if (result && result?.data?.success && state === 'Accept') {
      debounce(updateOrder, 200)({})
      //updateOrder(result.data)
    }

    return get(result, 'data', {})
  } catch (e) {
    console.error(e)
    return {}
  }
}

const eligiblePaymentMethods = async () => {
  try {
    const result = await axios.get(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/eligiblePaymentMethods`
    )
    console.log('eligiblePaymentMethods Result', result)
    if (result.data.errorCode) {
      console.error(result)
      return []
    } else {
      return get(result, 'data', [])
    }
  } catch (e) {
    console.error(e)
    return []
  }
}

const eligibleShippingMethods = async () => {
  try {
    const result = await axios.get(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/eligibleShippingMethods`
    )
    console.log('eligibleShippingMethods Result', result)
    if (result.data.errorCode) {
      console.error(result)
      return []
    } else {
      return get(result, 'data', [])
    }
  } catch (e) {
    console.error(e)
    return []
  }
}

const setShipping = async (params) => {
  try {
    const result = await axios.post(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/setShipping`,
      params
    )
    if (result.data.errorCode) {
      console.error(result)
      return get(result, 'data', [])
    }
    if (result.data.__typename === 'Order') {
      updateOrder(result.data)
      return get(result, 'data', {})
    }
  } catch (e) {
    console.error(e)
    return {}
  }
}
const generatePledgToken = async (paymentMethod) => {
  try {
    const result = await axios.get(
      `order/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/${paymentMethod}/generatePledgToken`
    )
    if (result.data.errorCode) {
      console.error(result)
      return {}
    } else {
      return get(result, 'data')
    }
  } catch (e) {
    console.error(e)
    return {}
  }
}

export {
  addItemToOrder,
  addPaymentToOrder,
  adjustOrderLine,
  getActiveOrder,
  removeOrderLine,
  applyCouponCode,
  removeCouponCode,
  eligiblePaymentMethods,
  eligibleShippingMethods,
  setShipping,
  validate3ds,
  validateCartInventory,
  generatePledgToken
}
